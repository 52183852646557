import { ApiConfig } from '@/config/types';
import defaultGetConfig from '@/config';
import { API_VERSION } from '@/api/index';
import { Attachment } from '@/models/form.model';

export const getApiPath = async (
  tenant: string,
  relativePath: string,
): Promise<string> => {
  const getConfigApiPromise: () => Promise<ApiConfig> = defaultGetConfig;
  return getConfigApiPromise().then(({ apiUrl }) => `${apiUrl}${relativePath}`);
};

export const getEntityDocumentDownloadURL = async (
  tenant: string,
  entityId: string,
  documentId: string,
  documentName: string,
): Promise<string> => {
  const path = `/${API_VERSION}/${tenant}/entities/${encodeURIComponent(
    entityId,
  )}/document/${encodeURIComponent(documentId)}/name/${encodeURIComponent(
    documentName,
  )
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')}`;
  return getApiPath(tenant, path);
};

export const getFormAttachmentDownloadURL = async (
  tenant: string,
  attachment: Attachment,
): Promise<string> => {
  const path = `/${API_VERSION}/${tenant}/forms/${encodeURIComponent(
    attachment.formId,
  )}/item/${encodeURIComponent(
    attachment.dataItemId,
  )}/attachment/${encodeURIComponent(attachment.id)}/name/${encodeURIComponent(
    attachment.fileName,
  )}`;
  return getApiPath(tenant, path);
};
